import { Badge, Button, ExpandableSection, Icon, IconSettings } from '@salesforce/design-system-react';
import React, { useState } from 'react';
import useMobileDetect from 'use-mobile-detect-hook';

const getKeywordList = (keyword) => {
  var regexp = /[^\s"]+|"([^"]*)"/gi;
  var result = [];

  do {
      //Each call to exec returns the next regex match as an array
      var match = regexp.exec(keyword);
      if (match != null)
      {
          //Index 1 in the array is the captured group if it exists
          //Index 0 is the matched text, which we use if no captured group exists
          result.push(match[1] ? match[1] : match[0]);
      }
  } while (match != null);
  return result
    .filter(x => !['and', 'or', 'not', '(', ')'].includes(x.toLowerCase()))
    .map(x => {
      x = x.replace('(', '').replace(')', '')
      return x;
    })
}

const ExpandableRow = ({data, salesforceUrl, keyword, onAskSF, type: salesforceType}) => {
  const [isOpen, setIsOpen] = useState(false);
  const detectMobile = useMobileDetect();

  const preventEvent = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  const popupWidth = 650;

  const gotoSalesforce = (company) => () => {
    if (!salesforceUrl) {
      onAskSF();
      return;
    }
    let url = `https://${salesforceUrl}.my.salesforce.com/_ui/search/ui/UnifiedSearchResults?str=${company}`
    if (salesforceType == 'lightning') {
      let encode = btoa('{"componentDef":"forceSearch:searchPage","attributes":{"term":"%s","scopeMap":{"type":"TOP_RESULTS"},"context":{"disableSpellCorrection":false,"disableIntentQuery":false,"permsAndPrefs":{"SearchUi.searchUIPilotFeatureEnabled":false,"SearchExperience.LeftNavEnhancementEnabled":true,"Search.crossObjectsAutoSuggestEnabled":true,"SearchResultsLVM.lvmEnabledForSearchResultsOn":true,"MySearch.userCanHaveMySearchBestResult":false,"SearchResultsLVM.lvmEnabledForTopResults":false,"OrgPermissions.UnionAppNavSmartScope":false,"SearchUi.feedbackComponentEnabled":false,"SearchExperience.TopResultsSingleSOSLEnabled":false,"OrgPreferences.ChatterEnabled":true,"Search.maskSearchInfoInLogs":false,"SearchUi.orgHasAccessToSearchTermHistory":false,"SearchUi.searchUIInteractionLoggingEnabled":false,"MySearch.userCanHaveMySearch":false},"searchDialogSessionId":"0","searchSource":"INPUT_DESKTOP"},"groupId":"DEFAULT"},"state":{}}'.replace('%s', company));
      url = `https://${salesforceUrl}.lightning.force.com/one/one.app#${encode}`
    }
    var left = (window.screen.width/2)-(popupWidth/2);
    window.open(url, 'Salesforce', `width=${popupWidth},height=800,left=${left}`);
  };

  const gotoLinkedin = company => () => {
    let link = `https://www.linkedin.com/search/results/companies/?keywords=${company}`
    var left = (window.screen.width/2)-(popupWidth/2);
    window.open(link, 'Linkedin', `width=${popupWidth},height=800,left=${left}`);
  }

  const companyName = data['Company Name'].split('-')[0];
  const keywordList = getKeywordList(keyword);
  const highlightText = (content) => {
    keywordList.forEach(x => {
      let regexp = new RegExp("\\b" +x + "\\b", 'gi');
      content = content.replace(regexp, (e) => {
        return `<span class="highlight">${e}</span>`
      })
    });
    return {__html: content};
  }
  
  const highlightBadge = (content) => {
    keywordList.forEach(x => {
      let regexp = new RegExp("\\b" +x + "\\b", 'gi');
      content = content.replace(regexp, (e) => {
        return `<span class="highlight">${e}</span>`
      })
    });
    return content.includes('highlight') ? 'success' : ''
  }

  const getKeywordsFromDescription = content => {
    return keywordList.map(x => {
      let regexp = new RegExp("\\b" +x + "\\b", 'gi');
      let list = Array.from(content.matchAll(regexp), m => m[0]);
      return list[0]
    }).filter(x => x)
  }

  const desktopHeader = (
    <div title={companyName} className="Table_Header">
      <div className="Table_Header__Column_2" onClick={preventEvent}>{companyName}</div>
      <div title="Search in Salesforce" className="Table_Header__Column_3" onClick={preventEvent}>
        <div onClick={gotoSalesforce(companyName)}>
          <img src="salesforce_logo.png"/>
          <Icon
            assistiveText={{ label: 'new_window' }}
            category="utility"
            color="light"
            name="new_window"
            size="x-small"
          />
        </div>
      </div>
      <div title="Search in Linkedin" className="Table_Header__Column_4" onClick={preventEvent}>
        <div onClick={gotoLinkedin(companyName)}>
          <img src="linkedin_logo.png"/>
          <Icon
            assistiveText={{ label: 'new_window' }}
            category="utility"
            color="light"
            name="new_window"
            size="x-small"
          />
        </div>
      </div>
      <div className="Table_Header__Column_5" onClick={preventEvent}>
        {getKeywordsFromDescription(data['Job Title'] + data['Job Description']).map(x => 
          <Badge color={'success'} content={x} />
        )}
      </div>
    </div>
  )

  const mobileHeader = (
    <div title={companyName} className="Table_Header__Mobile">
      <div onClick={preventEvent}>{companyName}</div>
      <div className="Table_Header__Mobile__Row2">
        <div title="Search in Salesforce" className="salesforce" onClick={preventEvent}>
          <div onClick={gotoSalesforce(companyName)}>
            <img src="salesforce_logo.png"/>
            <Icon
              assistiveText={{ label: 'new_window' }}
              category="utility"
              color="light"
              name="new_window"
              size="x-small"
            />
          </div>
        </div>
        <div title="Search in Linkedin" className="linkedin" onClick={preventEvent}>
          <div onClick={gotoLinkedin(companyName)}>
            <img src="linkedin_logo.png"/>
            <Icon
              assistiveText={{ label: 'new_window' }}
              category="utility"
              color="light"
              name="new_window"
              size="x-small"
            />
          </div>
        </div>
      </div>

      <div className="Table_Header__Mobile__Column_5" onClick={preventEvent}>
        {getKeywordsFromDescription(data['Job Title'] + data['Job Description']).map(x => 
          <Badge color={'success'} content={x} />
        )}
      </div>
    </div>
  );

  return (
    <IconSettings iconPath="/icons">
      <ExpandableSection
        isOpen={isOpen}
        onToggleOpen={(event, data) => {
          setIsOpen(!isOpen);
        }}
        title={detectMobile.isMobile() ? mobileHeader : desktopHeader}
        className="border-bottom"
      >
        <div className="Table_Body">
          <div className="Table_Body__Item">
            <div className="Table_Body__Item__Topic">Title</div>
            <div className="Table_Body__Item__Description" dangerouslySetInnerHTML={highlightText(data['Job Title'])}></div>
          </div>
          <div className="Table_Body__Item">
            <div className="Table_Body__Item__Topic">Description</div>
            <div className="Table_Body__Item__Description" dangerouslySetInnerHTML={highlightText(data['Job Description'])}></div>
          </div>
          <div className="Table_Body__Item">
            <div className="Table_Body__Item__Topic">Role</div>
            <div className="Table_Body__Item__Description" dangerouslySetInnerHTML={highlightText(data['Job Role'])}></div>
          </div>
          <div className="Table_Body__Item">
            <div className="Table_Body__Item__Topic">Location</div>
            <div className="Table_Body__Item__Description">{data['Job Location']}</div>
          </div>
          <div className="Table_Body__Item">
            <div className="Table_Body__Item__Topic">Salary</div>
            <div className="Table_Body__Item__Description">{data['Salary']}</div>
          </div>
        </div>
      </ExpandableSection>
    </IconSettings>
  )
}

ExpandableRow.defaultProps = {
  data: {
    'Company Name': '-',
    'Job Description': '-',
    'Job Location': '-',
    'Job Role': '-',
    'Job Title': '-',
    'Qualifications': '',
    'Salary': '-'
  }
}

export default ExpandableRow;
