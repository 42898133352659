
import React from 'react';
import DataList from './DataList';
import Searchable from './Searchable';

const MainContent = ({ data, isLoading, onSearch, keyword }) => {
  return (
    <div className="Main">
      <Searchable keyword={keyword} onSearch={onSearch}/>
      <DataList keyword={keyword} data={data} isLoading={isLoading} />
    </div>
  )
};

export default MainContent;