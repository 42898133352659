import React from 'react';

export default () => {
  return (
    <div className="Table_Header border-bottom">
      <div className="Table_Header__Column_1"></div>
      <div className="header Table_Header__Column_2">Company Name</div>
      <div className="header Table_Header__Column_3">Search in Salesforce</div>
      <div className="header Table_Header__Column_4">Search in Linkedin</div>
      <div className="header Table_Header__Column_5">NLP&nbsp;<span>(Tags display matched keywords and attributes related to your search query)</span></div>
      <div className="Table_Header__Column_6"></div>
    </div>
  )
}