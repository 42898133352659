import { Combobox, Settings } from '@salesforce/design-system-react';
import { Button, Card, ExpandableSection, IconSettings, Modal, Spinner } from '@salesforce/design-system-react';
// import settings from 'design-system-react/components/settings';
import React, { useState } from 'react';
import ExpandableRow from './ExpandableRow';
import TableHeader from './TableHeader';
import CsvDownloader from 'react-csv-downloader';
import useMobileDetect from 'use-mobile-detect-hook';


Settings.setAppElement('body');
export default ({ data, isLoading, keyword, ...props }) => {
  const [isHeaderOpen, setIsHeaderOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sfUrl, setSFUrl] = useState('');
  const validTypes = [{
    id: 'classic',
    label: 'Classic'
  }, {
    id: 'lightning',
    label: 'Lightning'
  }];
  const [selectedType, setSelectedType] = useState(validTypes[1]);
  const detectMobile = useMobileDetect();

  const searchResult = `Hiring Signals found the following (${data.count}) companies in relation to your search.`;

  const handleChangeDomain = (event) => {
    if (event.target.value && validateDomain(event.target.value)) {
      setSFUrl(event.target.value);
    }
  }
  const handleSaveDomain = () => {
    if (sfUrl) {
      setIsModalOpen(!isModalOpen);
    }
	};

  const closeDomainModal = () => setIsModalOpen(!isModalOpen);

  const validateDomain = (url) => {
    const invalid = /^(-|root|status)/i;
    const valid = /^([a-zA-Z0-9-]){1,40}$/;
  
    if (valid.test(url)){
      if (invalid.test(url)){
        return false;
      }
      return true;
    }
    else {
      return false;
    }
  }

  return (
    <div className="Main__List">
      {
        isLoading ?
        <Spinner
          size="large"
          variant="brand"
          assistiveText={{ label: 'Loading...' }}
        />
      : null}
      <Card className="Main__List__Body" heading="">
        <IconSettings iconPath="/icons">
          {data.list.length > 0 ?
            <CsvDownloader wrapColumnChar='"' separator=";" filename="hiring-signals.csv" datas={data.list}>
              <Button
                iconCategory="utility"
                iconName="download"
                iconPosition="left"
                label="Export CSV"
                variant="base"
                className="export-csv-btn"
              />
            </CsvDownloader>
          : null}
          <ExpandableSection
            isOpen={data.list.length > 0}
            nonCollapsible
            title="Hiring Signals&trade;"
            className={detectMobile.isMobile() ? "border-bottom" : ""}
          >
            <div className="Table_Body">
              <div>{searchResult}</div>
            </div>
          </ExpandableSection>
        </IconSettings>
        {data.list.length > 0 ? 
        (
          <>
            {detectMobile.isMobile() ? null : <TableHeader />}
            {data.list.map(item => 
              <ExpandableRow 
                keyword={keyword}
                type={selectedType.id} 
                salesforceUrl={sfUrl} 
                onAskSF={() => setIsModalOpen(true)} data={item}
              />)}
          </>
        )
        : !isLoading && keyword && keyword.length > 0 ?
        (
          <p>There are no results that match your query.</p>
        )
        : (
          <p>Please enter a search term to get started.</p>
        )}
      </Card>

      <Modal
        isOpen={isModalOpen}
        footer={[
          <Button label="Cancel" onClick={closeDomainModal} />,
          <Button label="Save" variant="brand" onClick={handleSaveDomain} />,
        ]}
        onRequestClose={closeDomainModal}
        heading="Salesforce Domain"
        className="SF_Question_Modal"
      >
        <section className="slds-p-around_medium">
          <div className="slds-form-element slds-m-bottom_large">
            <div className="slds-form-element__control">
              <input
                id="opptyName"
                className="slds-input"
                type="text"
                placeholder="Enter domain"
                onInput={handleChangeDomain}
              />
              <span>.my.salesforce.com</span>
            </div>

            <IconSettings iconPath="/icons">
              <Combobox
                events={{
                  onSelect: (event, data) => {
                    setSelectedType(data.selection[0])
                  },
                }}
                options={validTypes}
                selection={[selectedType]}
                variant="readonly"
              />
            </IconSettings>
          </div>
        </section>
      </Modal>
    </div>
  )
}