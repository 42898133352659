import { Icon, IconSettings } from "@salesforce/design-system-react";
import React from "react";
import "./style.scss";

const Header = (props) => {
  return (
    <div className="Header">
      <img className="Header__logo" src="logo.png" />
      <IconSettings iconPath="/icons">
        <Icon
          assistiveText={{ label: 'Warning' }}
          category="utility"
          color="base"
          name="help_center"
        />
      </IconSettings>
    </div>
  );
};

export default Header;
