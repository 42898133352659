import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, IconSettings } from '@salesforce/design-system-react';
import "./style.scss";
import Header from '../../components/Header/Header';
import MainContent from '../../components/Home/MainContent';
import Socket from '../../service/websocket';
import { useParams } from "react-router-dom";

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useState({
    page: 1, 
    keyword: '',
    noMore: false
  });
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  // const [count, setData] = useState([]);
  const loader = useRef(null);
  const searchRef = useRef({
    page: 0, 
    keyword: '',
    noMore: false
  });

  const params = useParams();
  

  const sendQuery = (query) => {
    if (Socket.readyState !== 1) return
    Socket.send(JSON.stringify(query));
    setIsLoading(true);
  }

  const handleSearch = (search, loadMore) => {
    if (search == searchRef.current.keyword && searchRef.current.noMore) return;

    let pageNumber = searchRef.current.page;
    let _keyword = search;

    if (!loadMore) {
      if (!search) return;
      setData([]);
      searchRef.current = {
        page: 1,
        keyword: _keyword,
        noMore: false
      };
      pageNumber = 1;
    } else {
      pageNumber += 1;
      searchRef.current = {
        ...searchRef.current,
        page: pageNumber
      };
    }

    var query = {
      query: searchRef.current.keyword, 
      page: searchRef.current.page
    };

    sendQuery(query);
  }

  useEffect(() => {
    Socket.onopen = () => {
      console.log('WebSocket Client Connected');
      const urlParams = new URLSearchParams(window.location.search);
      const query = urlParams.get('q')
      searchRef.current.keyword = query;
      setSearchParams(searchRef.current);
      handleSearch(query)
    };

    Socket.onmessage = (message) => {
      let result = JSON.parse(message.data);
      if (Array.isArray(result) && result.length == 0) {
        setIsLoading(false);
        return;
      }
      setCount(JSON.parse(message.data).count);
      let list = JSON.parse(message.data).data.map(x => ({ ...x, 'Company Name': x['Company Name'].split('-')[0]}));
      setData(oldData => {
        list = list.filter(x => {
          return oldData.filter(y => y['Company Name'] == x['Company Name'] && y['Job Title'] == x['Job Title'] && y['Job Description'] == x['Job Description']).length == 0
        });
        if (list.length == 0) {
          searchRef.current = { ...searchRef.current, noMore: true };
        }
        return [ ...oldData, ...list]
      });
      setIsLoading(false);
    };
  }, []);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting && !isLoading && searchRef.current.keyword !== "") {
      handleSearch(searchRef.current.keyword, true);
    }
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  return (
    <div className="Home">
      <Header />
      <MainContent
        keyword={searchParams.keyword}
        data={{count: count, list: data }} 
        isLoading={isLoading} 
        onSearch={handleSearch}
      />
      <div ref={loader} />
    </div>
  )
}

export default Home;