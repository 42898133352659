import React, { useState, useEffect } from 'react';
import { Button, IconSettings, Input, Tooltip } from '@salesforce/design-system-react';

export default ({ onSearch, keyword }) => {
  const [text, setText] = useState('');
  const tooltipContent = `You can search using boolean parameters like AND, OR, NOT. You may also wrap terms in quotation marks to narrow down to a specific group(s) of keywords such as "Junior Data Scientist" "Salesforce"`

  const handleKeypress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  }
  
  const handleChange = (event) => {
    setText(event.target.value);
  }

  const handleSearch = () => {
    if (text.length > 0) {
      // onSearch(text);
      window.location.href = '/search?q=' + text;
    }
  }

  useEffect(() => {
    if (keyword) setText(keyword);
  }, [keyword])

  return (
    <div className="Main__Searchable">
      <h3>
        <span>Search Criteria </span>
        <IconSettings iconPath="/icons">
          <Tooltip
            id="base"
            align="top left"
            content={tooltipContent}
            variant="learnMore"
          />
        </IconSettings>
      </h3>
      <div className="Main__Searchable__wrapper">
        <Input
          value={text}
          assistiveText={{ label: 'Search' }}
          placeholder="Search..."
          onInput={handleChange}
          onKeyPress={handleKeypress}
        />
        <Button label="Search" variant="brand" onClick={handleSearch} />
      </div>
    </div>
  )
}